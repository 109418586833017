$fonthead: 'Roboto',
sans-serif;
$fontstack: 'Roboto',
sans-serif;
$colordark: #3db4eb84;
$coloryellow: #FFD15C;
$colorpink: #3db5ed;
$colorblue: #6C6CE5;
$colordefault: #454360;
@mixin transition($second) {
    -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
    transition: all $second ease-in-out;
}